#products-list::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    overflow: overlay !important;
}

#products-list::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #10316D 0%, #295EBD 80%);
    border-radius: 10px;
}

#products-list::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(180deg, #10316D 0%, #295EBD 100%);
}

#products-list::-webkit-scrollbar-track{
    background: transparent;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
}

#products-list {
  scrollbar-width: thin;
  scrollbar-color: #10316D #fff;
  border-radius: 2rem;
}