.alice-carousel__prev-btn, .alice-carousel__next-btn {
    width: auto;
    padding: 0rem;
    height: 3rem;
    width: 3rem;
    background: rgba(0,0,0,0.3);
    border-radius: 100%;
}

.alice-carousel__prev-btn:hover,
.alice-carousel__next-btn:hover {
    background-color: rgba(0,0,0,0.6);
}

.alice-carousel__prev-btn {
    position: absolute;
    top: 41%;
    left: 1%;
}

.alice-carousel__next-btn {
    position: absolute;
    top: 41%;
    right: 1%;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    padding: 0rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    color: #fff;
}

.alice-carousel__prev-btn-item:hover, 
.alice-carousel__next-btn-item:hover {
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .alice-carousel__prev-btn, .alice-carousel__next-btn {
        height: 2rem;
        width: 2rem;
    }

    .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
        height: 2rem;
        width: 2rem;
    }
}
