@supports (-webkit-touch-callout: none) {
    .MuiTypography-root {
        -webkit-text-fill-color: #10316d !important;
    }
}

@media not all and (min-resolution:.001dpcm) { @media {
        .MuiTypography-root {
            -webkit-text-fill-color: #10316d !important;
        }
    }
}